// Cookiefirst
import config from "../config";

export default {

	methods: {
		putCookieFirstScriptInPlace(){
			console.log("--- COOKIEFIRST --------------------------------------");
			console.log("Deploying COOKIEFIRST");
			console.log("-----------------------------------------");
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);

			/* Put the code for this action here */
			script.setAttribute('data-cookiefirst-key',`3b35ef6e-d264-4d67-ba34-76b8a4115094`);

			document.body.prepend(script);
		}
	},
	beforeMount() {
		if(config.isWebsiteInProduction && !this.isPnGAction){
			//this.putCookieFirstScriptInPlace();
		}
	}

}
